<template>
  <v-dialog ref="dialog" v-model="dialog.display" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="picker.date"
        :disabled="disabled"
        :label="$t('updateCalendarDialog.datePickerLabel')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="picker.dateISO"
      :min="minDate"
      :max="maxDate"
      color="primary"
      :first-day-of-week="1"
    >
      <v-spacer />
      <v-btn text color="primary" @click="dialog.display = false">
        {{ $t('general.buttons.cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="onSave">
        {{ $t('general.buttons.validate') }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'UpdateCalendarDatePicker',

  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    milestone: {
      required: true,
    },
  },

  data: () => ({
    dialog: {
      display: false,
    },
    now: new Date(),
    picker: {
      date: '',
      dateISO: '',
      max: '',
      min: '',
    },
  }),

  computed: {
    minDate() {
      return this.picker.min.getTime() > this.now.getTime()
        ? this.getLocaleISOString(this.picker.min)
        : this.getLocaleISOString(this.now)
    },
    maxDate() {
      return this.picker.max.getTime() > this.now.getTime()
        ? this.getLocaleISOString(this.picker.max)
        : this.getLocaleISOString(this.now)
    },
  },

  watch: {
    milestone: function() {
      this.milestoneUpdated()
    },
  },

  created() {
    this.milestoneUpdated()
  },

  methods: {
    onSave() {
      this.$emit('update:milestone', {
        ...this.milestone,
        date: new Date(
            `${this.picker.dateISO}T${this.milestone.date.toLocaleTimeString('fr-FR', {hour12:false})}`,
        ),
      })
      this.milestoneUpdated()
      this.dialog.display = false
    },
    milestoneUpdated() {
      if (this.milestone) {
        this.picker.date = this.milestone.date.toLocaleDateString(
          navigator.language || 'fr-FR',
          {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
          }
        )

        this.picker.dateISO = this.milestone.date.toISOString()
                                  .split('T')[0]

        this.picker.min = this.milestone.minDate
        this.picker.max = this.milestone.maxDate
      }
    },
  },
}
</script>
